<template>
<v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1200px">
        <v-card>
            <v-card-title>staff</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <br/>
                 <v-flex lg2>
                    <v-text-field         
                        append-icon="search"
                        dense
                        single-line
                        outlined
                        clearable
                        hide-details
                        :color="color.theme"
                    ></v-text-field>
                     </v-flex>
                <v-data-table
                :headers="headers"
                :items="staff"
                :hide-default-footer="true"
                @page-count="pageCount = $event"
                >
                <template v-slot:header.staff_name_th="{ header }"><span>{{$t(header.text)}}</span></template>
                <template v-slot:header.staff_storage="{ header }"><span>{{$t(header.text)}}</span></template>
                <template v-slot:item="props">
                    <tr><td width="90%" class="text-center">{{props.item.staff_name}}</td>
                    <td width="10%" class="text-center">{{props.item.staff_storage}}</td>
                    </tr>
                </template>
                </v-data-table>
            </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  :color="color.theme" :dark="color.darkTheme" @click="$emit('closemanagestoragestaff')">Submit</v-btn>
          <v-btn color="red" outlined @click="$emit('closemanagestoragestaff')">close</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
export default {
   mixins: [validationMixin],
   validations: {
      filename: { required },
    },
    props: ['show'],
    data: function() {
      return {
       headers:[{
           text: "Name",
          align: "left",
          value: "staff_name_th",
          width: "90%",
          sortable: false
       },{
           text: "Storage",
          align: "left",
          value: "staff_storage",
          width: "10%",
          sortable: false
       }],
       staff:[{
           staff_name:'maprang',
           staff_storage:'2 GB'
       },{
           staff_name:'mamaiew',
           staff_storage:'2 GB'
       }]
      }

    },
    computed: {
      ...mapState(['username', 'authorize','account_active','color']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                 
                }
                return this.show
            },
            set (value) {
                if (!value) {
                 
                }
            }
        },
        
    },
    methods: {
      zoomOut () {
        this.zoom = (this.zoom - 1) || 0
      },
      zoomIn () {
        this.zoom = (this.zoom + 1) || 100
      },
    }
}
     


</script>
<style>

</style>